/**
 * @flow
 */

import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import ResetPasswordPage from "../components/Auth/ResetPasswordPage"

type Props = {
  location: Object,
}

const ForgotPassword = ({ location }: Props) => {
  const menuItems = [
    // { name: "Sign In", path: "/sign-in" },
    { name: "Leaderboard", path: "/leaderboard" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Reset Password" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <ResetPasswordPage location={location} />
      <Footer back />
    </>
  )
}

export default ForgotPassword
