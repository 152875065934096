/**
 * @flow
 */
import React from "react"
import styled from "styled-components"
import { Field, Form as FormikForm, Formik } from "formik"
import * as Yup from "yup"

import media from "../../../styles/media"
import { Button, TextField, PasswordField } from "../../elements"

type Values = {
  code: string,
  password: string,
  passwordConfirmation: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  passwordConfirmation: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords need to match"),
})

const Container = styled.div`
  .inline-form {
    display: flex;
    flex-direction: column;
  }
  ${media.tablet`
    .inline-form {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`

const Form = ({
  onSubmit,
  initialValues = {
    code: "",
    password: "",
    passwordConfirmation: "",
  },
}: Props) => {
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <FormikForm noValidate>
            <div className="inline-form">
              <Field
                component={PasswordField}
                id="password"
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
                required
              />
              <Field
                component={PasswordField}
                id="passwordConfirmation"
                label="Confirm Password"
                name="passwordConfirmation"
                type="password"
                placeholder="Confirm Password"
                // help="Passwords need to match"
                required
              />
            </div>
            <div className="inline-form">
              <Field
                autoFocus
                component={TextField}
                id="code"
                label="Confirmation Code"
                name="code"
                placeholder="Code"
                help="From the confirmation email"
                required
              />
              <Button
                type="submit"
                label="Reset"
                className="mouse-link"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    </Container>
  )
}

export default Form
